<template>
  <div>
    <GatyaCore
      :mode="this.mode"
      :shared="this.shared == 'true'"
      :idlist="[this.id0, this.id1, this.id2]"
    ></GatyaCore>
  </div>
</template>

<script>
// @ is an alias to /src
import GatyaCore from "@/components/Gatya.vue";

export default {
  name: "Home",
  props: ["mode", "shared", "id0", "id1", "id2"],
  data() {
    return {};
  },
  components: {
    GatyaCore,
  },
  methods: {},
};
</script>

<style scoped>
</style>