import mother_goods from "../assets/mother-goods.json"
import redbull_goods from "../assets/redbull-goods.json"
import yasai_goods from "../assets/yasai-goods.json"

import { randNumbersNoConfilict } from "./tools.js"

export { ModeList, Gatya, GetResult }

const goods = {
    "normal": mother_goods,
    "redbull": redbull_goods,
    "yasai": yasai_goods
}

const ModeList = ["redbull", "normal", "yasai"]

/**
 * @typedef MotherGoods
 * @type {object}
 * @property {number} id id
 * @property {string} title 商品名
 * @property {number} price_taxed 税込み価格
 * @property {number} price 本体価格
 * @property {string} price_text 価格テキスト
 * @property {string} img_src img_src
 * @property {string} goods_url 商品url
 */

/**
 * ガチャを引いて結果を返す
 * @param {number} n ガチャ回数
 * @param {String} [mode="normal"] モード
 * @returns {Array.<MotherGoods>} 結果リスト
 */
function Gatya(n, mode = "normal") {
    if (ModeList.indexOf(mode) == -1) {
        mode = "normal"
    }
    let goods_len = goods[mode].length - 1;
    let rand_list = randNumbersNoConfilict(0, goods_len, n)
    return GetResult(rand_list, mode)
}

/**
 * IDからガチャ結果を返す
 * @param {number} n ガチャ回数
 * @param {String} [mode="normal"] モード
 * @returns {Array.<MotherGoods>} 結果リスト
 */
function GetResult(index_list, mode) {
    let gatya_result = []
    for (const index of index_list) {
        const good_result = goods[mode][index]
        if (good_result == undefined) {
            throw "Not found Item"
        } else {
            gatya_result.push(good_result)
        }
    }
    return gatya_result
}