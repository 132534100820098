<template>
  <v-btn color="primary" outlined @click="tweet()"
    >Tweet
    <v-icon>mdi-twitter</v-icon>
  </v-btn>
</template>

<script>
/**
 * @typedef MotherGoods
 * @type {object}
 * @property {number} id id
 * @property {string} title 商品名
 * @property {number} price_taxed 税込み価格
 * @property {number} price 本体価格
 * @property {string} price_text 価格テキスト
 * @property {string} img_src img_src
 * @property {string} goods_url 商品url
 */

export default {
  name: "Twitter",
  props: {
    /**@type {MotherGoods[]} */
    result: {
      type: Array,
      default: () => [
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: "",
        },
      ],
    },
    mode: {
      type: String,
      default: "normal",
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    tweet() {
      const share_url =
        location.protocol +
        "//" +
        location.host +
        `/result?shared=true&mode=${this.mode}&id0=${this.result[0].id}&id1=${this.result[1].id}&id2=${this.result[2].id}`;

      const tweet_intent =
        encodeURI(
          "https://twitter.com/intent/tweet?text=" +
            "お母さん食堂ガチャを引いたよ！\n" +
            `- ${this.result[0].title}\n` +
            `- ${this.result[1].title}\n` +
            `- ${this.result[2].title}\n` +
            `合計:${this.price}円\n` +
            "&hashtags=お母さん食堂ガチャ" +
            "&via=YasaiDev" +
            "&related=YasaiDev"
        ) + `&url=${encodeURIComponent(share_url)}`;

      this.$gtag.event("ツイート", {
        event_category: "シェア",
        event_label: "Share",
        value: 1,
      });
      window.open(tweet_intent, "_blank");
    },
  },
};
</script>

<style>
</style>