<template>
  <div>
    <Waiting></Waiting>
  </div>
</template>

<script>
// @ is an alias to /src
import Waiting from "@/components/Wainting.vue";

export default {
  name: "Home",
  data() {
    return {
      wasGatyatta: false,
    };
  },
  components: {
    Waiting,
  },
  methods: {},
};
</script>

<style scoped>
</style>