<template>
  <v-app>
    <v-app-bar
      color="white"
      clippedLeft
      app
      :collapse-on-scroll="$vuetify.breakpoint.xs"
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <router-link
        to="/"
        class="text-decoration-none font-weight-medium black--text"
      >
        <v-toolbar-title>お母さん食堂ガチャ</v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" mobile-breakpoint="700" clipped>
      <v-list nav>
        <v-list-item-group v-model="group">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>ガチャ</v-list-item-title>
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-title>これは何？</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-footer app absolute>
      <small><a href="https://twitter.com/YasaiDev">@YasaiDev</a></small>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      group: null,
    };
  },
};
</script>

<style>
</style>
