<template>
  <v-container>
    <v-row style="height: 450px" justify="center" align-content="center">
      <v-col cols="12">
        <v-card
          max-width="500px"
          height="350px"
          width="95%"
          title
          class="mx-auto"
        >
          <v-card-title class="justify-center"
            >お母さんに任せろ！！</v-card-title
          >

          <div class="mom-background">
            <div
              class="mom-waiting mom-box"
              v-if="isMomWaiting && !isMomComming"
            ></div>
            <transition name="mom-going" @after-enter="isMomComming = true">
              <div
                class="mom-box mom-going-box"
                v-if="!isMomWaiting && !isMomComming"
              ></div>
            </transition>
            <transition name="mom-comming" @after-enter="GatyaAniEnd()">
              <div
                class="blank-box"
                v-if="!isMomWaiting && isMomComming"
                :style="{
                  backgroundImage: 'url(' + this.ModeImg[this.GatyaMode] + ')',
                }"
              ></div>
            </transition>
          </div>

          <v-card-actions id="change_button" class="justify-center">
            <v-btn
              @click="GatyaAniStart()"
              outlined
              :loading="!isMomWaiting || isMomComming"
            >
              ガチャを引く💰
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { randRange } from "../utils/tools.js";

export default {
  name: "Waiting",
  data() {
    return {
      isMomWaiting: true,
      isMomComming: false,
      GatyaMode: "normal",
      ModeImg: {
        yasai: "/img/waiting/YasaiMom.png",
        redbull: "/img/waiting/Harpy.png",
        normal: "/img/waiting/Mom.png",
      },
    };
  },
  methods: {
    GatyaAniStart() {
      this.isMomWaiting = !this.isMomWaiting;
      this.$gtag.event("ガチャる", {
        event_category: "ボタンクリック",
        event_label: "Gatya",
        value: 1,
      });
    },
    GatyaAniEnd() {
      this.isMomComming = false;
      this.isMomWaiting = true;
      localStorage.setItem("NewGatya", "true");
      this.$router.push({ path: "result", query: { mode: this.GatyaMode } });
    },
    DefineGatyaMode() {
      const rand = randRange(0, 10);
      if (rand === 8) {
        return "yasai";
      } else if (rand === 7) {
        return "redbull";
      } else {
        return "normal";
      }
    },
  },
  created() {
    this.GatyaMode = this.DefineGatyaMode();
  },
};
</script>

<style scoped>
.mom-box {
  width: 100%;
  height: 200px;
  background-image: url(/img/waiting/Mom.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.blank-box {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.mom-background {
  width: 100%;
  height: 200px;
  background-image: url(/img/waiting/Store.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.mom-going-box {
  transform: rotate(360deg) scale(0.1);
}

.mom-waiting {
  animation: 1.5s ease 0s infinite normal none running WaitAnimation;
}

@keyframes WaitAnimation {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 10px) scaleY(0.9);
  }
}

.mom-going-enter-active {
  animation: 2s ease 0s 1 normal forwards running going;
}

@keyframes going {
  0% {
    transform: translate(0px, 0px) scale(1);
  }

  50% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(360deg) scale(0.1);
  }
}

.mom-comming-enter-active {
  animation: 2s ease 0s 1 normal forwards running comming;
}

@keyframes comming {
  0% {
    transform: translate(0px, 0px) scale(0.1);
  }

  50% {
    transform: rotate(10deg) scale(0.5);
  }

  100% {
    transform: rotate(-360deg) scale(1);
  }
}

.mom-going-leave,
.mom-comming-leave {
  opacity: 0;
}
</style>
