<template>
  <div>
    <div id="gatya-result">
      <v-container fluid>
        <v-row
          v-for="(item, index) in gatya_result"
          :key="index"
          justify="center"
          align-content="center"
        >
          <v-col>
            <v-card
              elevation="2"
              tile
              height="290px"
              width="95%"
              max-width="500px"
              class="mx-auto"
            >
              <a
                :href="item.goods_url"
                target="_blank"
                rel="noopener"
                class="text-decoration-none"
              >
                <v-img
                  class="black--text align-end"
                  height="200px"
                  :src="item.img_src"
                >
                  <v-card-title id="goods-title" class="font-weight-bold">{{
                    item.title
                  }}</v-card-title>
                </v-img>
              </a>

              <v-card-subtitle class="pb-0">
                {{ item.price_text }}
              </v-card-subtitle>
              <v-card-actions id="change_button">
                <v-btn @click="ChangeGatya(index)" outlined small>
                  Change
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row id="sum_price" justify="center" align-content="center">
          <v-col>
            <v-card width="95%" max-width="500px" class="mx-auto">
              <v-card-title> 合計: {{ sum_price }}円 </v-card-title>
              <v-card-subtitle>{{ mom_word }}</v-card-subtitle>
              <v-card-actions id="change_button">
                <v-btn @click="ReGatya()" outlined>
                  {{ this.shared ? "ガチャを引く" : "もう一度引く" }}
                </v-btn>
                <Twitter
                  :mode="this.mode"
                  :result="this.gatya_result"
                  :price="sum_price"
                ></Twitter>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { Gatya, ModeList, GetResult } from "../utils/gatya-core";
import Twitter from "./Twitter.vue";
import MomWords from "../assets/mom-words.json";

/**
 * @typedef MotherGoods
 * @type {object}
 * @property {number} id id
 * @property {string} title 商品名
 * @property {number} price_taxed 税込み価格
 * @property {number} price 本体価格
 * @property {string} price_text 価格テキスト
 * @property {string} img_src img_src
 * @property {string} goods_url 商品url
 */

export default {
  name: "GatyaCore",
  props: {
    mode: {
      validator: (val) => ModeList.indexOf(val) !== -1,
      default: "normal",
    },
    shared: {
      type: Boolean,
      default: false,
    },
    idlist: {
      type: Array,
    },
  },
  data() {
    return {
      /**@type {MotherGoods[]} */
      gatya_result: [],
      mom_word: "",
    };
  },
  mounted() {
    if (this.shared) {
      this.LoadGatyaFromID();
    } else {
      // ガチャ実行フラグ
      if (localStorage.getItem("NewGatya")) {
        if (localStorage.getItem("NewGatya") == "true") {
          this.NewGatya();
        } else if (localStorage.getItem("NewGatya") == "false") {
          this.LoadGatyaFromLocal();
        }
      } else {
        this.NewGatya();
      }
    }
  },
  methods: {
    NewGatya() {
      this.gatya_result = Gatya(3, this.mode);
      localStorage.setItem("GatyaResult", JSON.stringify(this.gatya_result));
      localStorage.setItem("GatyaMode", this.mode);
      localStorage.setItem("NewGatya", "false");
    },
    LoadGatyaFromLocal() {
      try {
        this.gatya_result = JSON.parse(localStorage.getItem("GatyaResult"));
        this.mode = localStorage.getItem("GatyaMode");
      } catch (e) {
        localStorage.removeItem("GatyaResult");
        this.gatya_result = Gatya(3, this.mode);
      }
    },
    LoadGatyaFromID() {
      try {
        this.gatya_result = GetResult(this.idlist, this.mode);
      } catch (e) {
        this.$router.push({ path: "/" });
      }
    },
    ChangeGatya(index) {
      this.gatya_result.splice(index, 1, Gatya(1, this.mode)[0]);
      localStorage.setItem("GatyaResult", JSON.stringify(this.gatya_result));
      this.$gtag.event("チェンジ", {
        event_category: "ボタンクリック",
        event_label: "Change",
        value: 1,
      });
    },
    ReGatya() {
      this.$gtag.event("もう一度", {
        event_category: "ボタンクリック",
        event_label: "もう一度",
        value: 1,
      });
      this.$router.push({ path: "/" });
    },
  },
  computed: {
    sum_price: function () {
      let sum = 0;
      for (const obj of this.gatya_result) {
        sum += obj.price_taxed;
      }
      return sum;
    },
  },
  watch: {
    gatya_result: function () {
      this.mom_word =
        MomWords[this.mode][
          Math.floor(Math.random() * MomWords[this.mode].length)
        ];
    },
  },
  components: {
    Twitter,
  },
};
</script>

<style>
#goods-title {
  background-color: rgba(255, 255, 255, 0.5);
}
</style>