export { randRange, randNumbersNoConfilict }

/**
 * 最小値から最大値未満の乱数を返す
 * @param {number} min 最小値
 * @param {number} max 最大値
 * @returns {number} 最小値から最大値未満の乱数
 */
function randRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * 最小値から最大値未満の重複がない乱数をn個リストで返す
 * @param {number} min 最小値
 * @param {number} max 最大値
 * @param {number} n 乱数の数
 * @returns {Array} 最小値から最大値未満の重複がない重複がないn個の乱数リスト
 */
function randNumbersNoConfilict(min, max, n) {
    let before = min - 1;
    let result = [];
    let rand = 0;

    if (Math.abs(max - min) >= n) {
        console.log("重複せーへん...")
        for (let index = 0; index < n; index++) {
            do {
                rand = randRange(min, max);
            } while (before === rand)
            result.push(rand);
            before = rand;
        }
    } else {
        console.log("重複しちゃった...")
        for (let index = 0; index < n; index++) {
            rand = randRange(min, max);
            result.push(rand);
            before = rand;
        }
    }

    return result
}

